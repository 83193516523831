const routes = [
  {
    path: ['/', '/home'],
    exact: true,
    component: 'Home',
  },
  {
    path: ['/careers'],
    exact: true,
    component: 'Careers',
  },
  {
    path: ['/blog'],
    exact: true,
    component: 'Blog',
    protected: false,
  },
  {
    path: ['/signup'],
    exact: true,
    component: 'SignUp',
  },
  {
    path: ['/admin'],
    exact: true,
    component: 'Admin',
    protected: true,
  },
];

export default routes;
