import { lazy, Suspense, memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import Footer from '../components/Footer';
import routes from './config';
import { Styles } from '../styles/styles';
import SignUp from '../pages/Auth/SignUp';
import { useAuth } from '../context/auth/AuthContext';

const Router = memo(() => {
  const { isAuthenticated } = useAuth();

  return (
    <Suspense fallback={null}>
      <Styles />

      <Switch>
        <Route path="/signup" component={SignUp} />
        {routes.map((route, index) =>
          route.protected && !isAuthenticated ? (
            <Route path="/signup" component={SignUp} />
          ) : (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={lazy(() => import(`../pages/${route.component}`))}
            />
          )
        )}
      </Switch>

      <Footer />
    </Suspense>
  );
});

export default Router;
