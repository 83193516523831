import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/auth/AuthContext';

const SignUp: React.FC = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const authContext = useContext(AuthContext);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/local/register', {
        username,
        email,
        password,
      });
      // Handle successful sign-up
      console.log('Sign-up successful');
      if (authContext) {
        authContext.login(response.data.jwt, response.data.user.id);
      }
    } catch (error) {
      // Handle sign-up error
      console.error('Sign-up error:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button type="submit">Sign Up</button>
    </form>
  );
};

export default SignUp;
